<template>
  <div>

    <v-container fluid>

      <v-overlay :value="overlay"
                 color="white"
                 opacity="0.6">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row justify="center">
        <v-col cols="12" lg="9">
          <v-stepper v-model="step" class="shadow">
            <v-stepper-header class="shadow">
              <v-stepper-step color="success"
                              :complete="step > 1"
                              step="1"
              >
                Sélectionnez les points de vente
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="success"
                              :complete="step > 2"
                              step="2"
              >
                Déterminer les gagnants
              </v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">

                <v-card class="pa-4 pa-lg-10">
                  <v-card-text>

                    <h1 class="text-center mb-5">
                      Participants à la campagne
                    </h1>

                    <p class="text-center fs-17 mb-10">
                      Filtrer les points de ventes selon les critères ci-dessous :
                    </p>

                    <p>
                      Types (optionnel) :
                    </p>
                    <v-select outlined
                              deletable-chips
                              v-model="filter.types"
                              placeholder="Sélectionnez les types"
                              multiple
                              clearable
                              :items="types"
                              item-value="name"
                              item-text="name"></v-select>


                    <p>
                      Regions (optionnel) :
                    </p>
                    <v-select outlined
                              v-model="filter.types"
                              hide-details
                              placeholder="Sélectionnez les régions"
                              clearable
                              multiple
                              :items="regions"
                              item-value="name"
                              item-text="name"></v-select>


                  </v-card-text>

                  <v-card-actions>
                    <v-spacer/>
                    <v-btn depressed
                           @click="searchPos"
                           color="primary"
                    >
                      <v-icon left>mdi-magnify</v-icon>
                      Rechercher
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </v-stepper-content>

              <v-stepper-content step="2">

                <v-card class="pa-4 pa-lg-10" v-if="!isAnimating && !winners.length">
                  <v-card-text>

                    <div class="text-center mb-10 pa-10 grey lighten-3 rounded">

                      <p class="fs-17">
                        Nombre de points de vente participants
                      </p>

                      <h1>
                        {{ wholesalers.length }}
                      </h1>
                    </div>

                    <p>
                      Nombre de gagnants (obligatoire) :
                    </p>
                    <v-text-field outlined
                                  v-model="winningCount"
                                  placeholder="Nombre de gagnants"
                                  hide-details
                                  multiple
                                  clearable
                                  min="1"
                                  type="number"
                    ></v-text-field>


                  </v-card-text>

                  <v-card-actions>
                    <v-btn text
                           @click="step = 1"
                    >
                      <v-icon left>mdi-arrow-left</v-icon>
                      Retour
                    </v-btn>
                    <v-spacer/>
                    <v-btn depressed
                           :disabled="!wholesalers.length"
                           @click="runTirage"
                           color="success"
                    >
                      Démarrer !
                      <v-icon right>mdi-play</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>


                <v-card v-if="isAnimating" class="pa-4 pa-lg-10">
                  <v-card-text>

                    <h3 class="animated text-center mb-10 primary--text">
                      {{ animatedName }}
                    </h3>

                    <div class="text-center">
                      <v-progress-circular
                          size="150"
                          width="10"
                          :value="Math.round(progressValue / 10) * 10"
                          max="100"
                          color="success"
                      >
                        <h1>
                          {{ Math.round(progressValue / 10) }}
                        </h1>
                      </v-progress-circular>
                    </div>

                  </v-card-text>
                </v-card>


                <v-card v-if="!isAnimating && winners.length" class="pa-4 px-lg-10 pb-lg-10">
                  <v-card-text>

                    <v-layout row wrap align-center>
                      <v-flex>
                        <LottieAnimation :style="{height: '200px'}"
                                         :animation-data="WatermelonJSON"
                                         :auto-play="true"
                                         :loop="true"
                                         class="lottie-animation"
                        />
                      </v-flex>
                    </v-layout>

                    <h2 class="animated text-center mb-10">
                      <span class="text-primary">
                        Félicitations!
                      </span>
                       voici les gagnants
                    </h2>

                    <v-list v-for="(winner, index) in winners" :key="index" outlined class="mb-2 rounded-lg animated">
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon color="success">mdi-check-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="font-weight-bold">
                          {{ winner.name }}
                          <v-list-item-subtitle class="font-weight-medium primary--text">
                            Code : {{ winner.code }}
                          </v-list-item-subtitle>
                        </v-list-item-title>
                        <v-list-item-action>
                          <h3 class="primary--text"> {{ index + 1 }}</h3>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                  </v-card-text>
                  <v-card-actions>
                    <v-btn text
                           @click="step = 1"
                    >
                      <v-icon left>mdi-arrow-left</v-icon>
                      Retour
                    </v-btn>
                    <v-spacer/>
                    <v-btn depressed
                           @click="saveResult"
                           color="primary"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Enregistrer
                    </v-btn>
                  </v-card-actions>
                </v-card>


              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import LottieAnimation from 'lottie-web-vue'
import WatermelonJSON from "@/assets/win.json"

export default {
  components: {
    LottieAnimation
  },
  data() {
    return {
      WatermelonJSON: WatermelonJSON,
      overlay: false,
      step: 1,
      wholesalers: [],
      winners: [],
      isAnimating: false,
      animatedName: '',
      progressValue: 0,
      winningCount: 1,
      filter: {},
      types: [],
      regions: [],
    };
  },
  methods: {
    runTirage() {
      let weightedWholesalers = [];
      this.isAnimating = true;
      this.winners = [];
      this.progressValue = 0;


      this.wholesalers.forEach(wholesaler => {
        for (let i = 0; i < wholesaler.overall_points; i++) {
          weightedWholesalers.push(wholesaler);
        }
      });

      const intervalDuration = 100;
      const totalDuration = 10000;
      const steps = totalDuration / intervalDuration;
      const stepIncrement = 100 / steps;

      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * weightedWholesalers.length);
        this.animatedName = weightedWholesalers[randomIndex].name;

        if (this.progressValue < 100) {
          this.progressValue += stepIncrement;
        } else {
          this.progressValue = 100;
        }

        if (this.progressValue >= 100) {
          clearInterval(interval);
          this.selectWinners();
        }
      }, intervalDuration);
    },
    selectWinners() {
      let weightedWholesalers = [];
      this.wholesalers.forEach(wholesaler => {
        for (let i = 0; i < wholesaler.overall_points; i++) {
          weightedWholesalers.push(wholesaler);
        }
      });

      let selectedWinners = [];
      while (selectedWinners.length < this.winningCount && weightedWholesalers.length > 0) {
        const randomIndex = Math.floor(Math.random() * weightedWholesalers.length);
        const winner = weightedWholesalers[randomIndex];

        if (!selectedWinners.includes(winner)) {
          selectedWinners.push(winner);
        }

        weightedWholesalers = weightedWholesalers.filter(w => w.name !== winner.name);
      }

      this.winners = selectedWinners;

      this.isAnimating = false;
    },
    getWholesalersBaseFilter() {
      this.overlay = true
      HTTP.get('/raffle-campaigns/wholesalers/base-filter').then((res) => {
        this.overlay = false
        this.types = res.data?.data?.types || []
        this.regions = res.data?.data?.regions || []
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    },
    searchPos() {
      this.overlay = true
      HTTP.get('/raffle-campaigns/wholesalers/filter', {
        params: {
          types: this.filter.types,
          regions: this.filter.regions,
          raffle_campaign_id: parseInt(this.$route.params.id),
        }
      }).then((res) => {
        this.overlay = false
        this.wholesalers = res.data.data ?? []
        this.winners = []
        this.winningCount = 1
        this.step = 2
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    },
    saveResult() {
      this.overlay = true
      HTTP.post('/raffle-campaigns/draw/save-result', {
        raffle_campaign_id: parseInt(this.$route.params.id),
        winners: this.winners,
      }).then(() => {
        this.$successMessage = "Résultats enregistrés avec succès !"
        this.overlay = false
        this.step = 1
        this.filter = {}
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    },
  },
  created() {
    this.getWholesalersBaseFilter()
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: fadeIn 1s ease-out;
}
</style>